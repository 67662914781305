import Vue from "vue";
import Component from "vue-class-component";
import { Privilege } from "@/models/privilege.interface";
import { translatePrivileges } from "./../utils/translatePrivileges";

@Component({})
export class Permissions extends Vue {
  readablePrivilege(privilege: Privilege, only = false) {
    if (only) {
      return translatePrivileges(privilege.name);
    } else {
      switch (privilege.type) {
        case this.$constants.TYPE_OF_PRIVILEGES.C:
          return this.$t("Privileges.C", {
            name: translatePrivileges(privilege.name),
          });
        case this.$constants.TYPE_OF_PRIVILEGES.R:
          return this.$t("Privileges.R", {
            name: translatePrivileges(privilege.name),
          });
        case this.$constants.TYPE_OF_PRIVILEGES.U:
          return this.$t("Privileges.U", {
            name: translatePrivileges(privilege.name),
          });
        case this.$constants.TYPE_OF_PRIVILEGES.D:
          return this.$t("Privileges.D", {
            name: translatePrivileges(privilege.name),
          });
      }
    }
  }
}

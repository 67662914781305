import store from "@/store/index";

const PRIVILEGE_TRANSLATE = {
  es: {
    ACCOUNT: "CUENTA",
    BALANCE: "BALANCE",
    BILL: "FACTURA",
    CATEGORY: "CATEGORÍA",
    "BILL COMMENT": "COMENTARIO EN FACTURA",
    "PRODUCT COMMENT": "COMENTARIO EN PRODUCTO",
    COMPANY: "COMPAÑÍA",
    CONFIGURATION: "CONFIGURACIÓN",
    DASHBOARD: "PANEL DE RESUMEN",
    DETAIL: "DETALLE",
    FAQ: "PREGUNTAS FRECUENTES",
    FEEDBACK: "RETROALIMENTACIÓN",
    CAROUSEL: "CARRUSEL",
    FUNCTIONALITY: "FUNCIONALIDAD",
    KYC: "CTC",
    NEWSLETTER: "BOLETÍN INFORMATIVO",
    PAYMENT: "PAGO",
    PLATFORM: "PLATAFORMA DE PAGO",
    PRODUCT: "PRODUCTO",
    ROLE: "ROL",
    "SOCIAL NETWORK": "RED SOCIAL",
    STYLE: "ESTILO",
    TERM: "TÉRMINO",
    USER: "USUARIO",
    WISHLIST: "LISTA DE DESEOS",
    WITHDRAWAL: "RETIROS",
    APPLICATION: "SOLICITUDES",
  },
};

export function translatePrivileges(privilege = "UNKNOWN") {
  const currentLanguage = store.getters["language/getLanguage"];

  if (
    PRIVILEGE_TRANSLATE[currentLanguage] !== undefined &&
    PRIVILEGE_TRANSLATE[currentLanguage][privilege] !== undefined
  ) {
    return PRIVILEGE_TRANSLATE[currentLanguage][privilege];
  } else {
    return privilege;
  }
}
